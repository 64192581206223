import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import perfil from "../../../static/img/irstrat/perfil.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Profile"/>
            <Banner title="Profile"/>
            <section className="executive_area perfil">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="ex_content">
                                <h2>Who we are?</h2>
                                <span className="sec_border"></span>
                                <p>
                                    We are a Mexican boutique-consulting firm focused on serving the Latin-American
                                    market, for which we have
                                    offices in Mexico and Canada. We specialize in comprehensive Investor Relations,
                                    implemented in the right direction
                                    for achieving outstanding and tangible results, covering all its core areas:
                                    corporate finance,
                                    communication and marketing, trading, strategic PR and financial law.
                                </p>
                                <p>We carry out specific mandates such as underpinning of private companies’ practices;
                                    preparation for
                                    fixed-income placements; Pre-IPO processes; preparation for IPO; perception
                                    management; and advice for
                                    improving liquidity.</p>
                                <p>We have the best team of professionals, we have been IROs, investment bankers,
                                    traders and analysts,
                                    and we have been involved in the execution of complex operations in the
                                    Latin-American market (M&As,
                                    divestitures, and debt and equity placements).
                                </p>
                                <p>We believe that the best investment lies in achieving an optimal positioning and
                                    perception in the
                                    financial markets, which is in turn required to receive a fair valuation and improve
                                    the liquidity of
                                    any security.</p>
                                <br/>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img className="wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={perfil} alt="Perfil"/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
